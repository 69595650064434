import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MapComponent from "../MapComponent";
import "../../style/index.css";
// import User from "../../asserts/images/user.png";
import JourneyDetails from "./JourneyDeatails";
import ChangeOrder from "./ChangeJourneyOrder";
import ReverseRoute from "./ReverseRoute";
import { useParams } from "react-router-dom";

const ViewJourney = () => {
  const [journeyList, setJourneyList] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [changeModalShow, setChangeModalShow] = useState(false);
  const [status,setStaus] = useState("");
  const [message, setMessage] = useState("");

  const id = useParams().id;
 

  useEffect(() => {
    axios
      .get(
        "http://35.192.79.231:9090/ROUTING-SERVICE/api/v1/journey/getJourneyAndRouteByDG_Id/"+id
      )
      .then((response) => {
        setJourneyList(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [message, status]);

  const onChecked = (journey, isChecked, colors) => {
    const color = {
      color: colors,
    };
    const location = getLocationRequest(journey);
    location.color = color;
    if (isChecked) {
      setSelectedLocations((prevSelectedLocations) => [
        ...prevSelectedLocations,
        location,
      ]);
    } else {
      setSelectedLocations((prevSelectedLocations) =>
        prevSelectedLocations.filter(
          (selectedLocation) => !areLocationsEqual(selectedLocation, location)
        )
      );
    }
  };

  // Function to check if two locations are equal
  function areLocationsEqual(location1, location2) {
    if (location1.waypoints.length !== location2.waypoints.length) {
      return false;
    }
    return location1.waypoints.every((location, index) => {
      // Check if index is out of bounds or waypoint is null
      if (
        index >= location2.waypoints.length ||
        location2.waypoints[index] === null
      ) {
        return false;
      }
      // Compare latitude and longitude
      return (
        location.location.lat === location2.waypoints[index].location.lat &&
        location.location.lng === location2.waypoints[index].location.lng
      );
    });
  }

    useEffect(() => {
      if (status === "SUCCESS") {
        handleSuccess(message);
      } else if (status === "ERROR") {
        handleError(message);
      }
    }, [message, status]);

    

  return (
    <div>
      <div className="d-flex p-3 py-5">
        <div style={{ width: "30%" }} className="p-3 shadow">
          <div className="d-flex flex-row justify-content-between">
            <Count name="Sheduled" count="15" />
            <Count name="Unsheduled" count="5" />
            <Count name="Total" count="20" />
            <Count name="Route" count="3" />
          </div>
          <div className="d-flex flex-column p-3">
            <h5>Drivers</h5>
            {journeyList.map((journey, index) => (
              <div
                className="driver-box d-flex"
                key={journey.journeyResponse.id}
              >
                <input
                  className="check-box"
                  type="checkbox"
                  onChange={(e) =>
                    onChecked(
                      journey,
                      e.target.checked,
                      getColorForRoute(index + 1)
                    )
                  }
                />
                <DriverBar
                  name={journey.journeyResponse.driverResponse.name}
                  distanse={convertDistance(
                    journey.journeyResponse.totalDistance
                  )}
                  duration={convertDuration(
                    journey.journeyResponse.totalDuration
                  )}
                  orders={journey.orderAndStopResponseList.length}
                  index={index + 1}
                />
              </div>
            ))}
          </div>
        </div>
        <div style={{ width: "70%" }} className="shadow">
          <MapComponent routes={selectedLocations} />
          <div className="d-flex p-3">
            <div
              className="map-button py-2 px-3"
              onClick={() => setChangeModalShow(true)}
            >
              <i className="fa-solid fa-up-down"></i>
            </div>
            <div
              className="map-button py-2 px-3"
              onClick={() => setModalShow(true)}
            >
              <i className="fa-solid fa-repeat"></i>
            </div>
          </div>
          <ReverseRoute
            show={modalShow}
            onHide={() => setModalShow(false)}
            journeyList={journeyList}
            setStaus={setStaus}
            setMessage={setMessage}
          />
          <ChangeOrder
            show={changeModalShow}
            onHide={() => setChangeModalShow(false)}
            journeyList={journeyList}
            setStaus={setStaus}
            setMessage={setMessage}
          />
          <JourneyDetails journeyList={journeyList} />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="Bounce"
      />
    </div>
  );
};

const Count = ({ name, count }) => {
  return (
    <div className="journey-box-count d-flex flex-column shadow p-2">
      <h5>{count}</h5>
      <p>{name}</p>
    </div>
  );
};

const DriverBar = ({ name, distanse, duration, orders, index }) => {
  const driverInitial = name.charAt(0).toUpperCase();
  const backgroundColor = getColorForRoute(index);

  return (
    <div
      className="driver-deatails d-flex align-items-center px-3"
      style={{ width: "100%" }}
    >
      <div className="driver-initial " style={{ backgroundColor }}>
        {driverInitial}
      </div>
      <div className="d-flex flex-column px-3" style={{ width: "100%" }}>
        <div>
          <p className="driverbar_value">{name}</p>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <i className="fa-regular fa-clock"></i>
            <p className="driverbar_value">{duration}</p>
          </div>

          <div className="d-flex align-items-center">
            <i className="fa-solid fa-location-dot"></i>
            <p className="driverbar_value">{orders}</p>
          </div>
          <div className="d-flex align-items-center">
            <i className="fa-solid fa-arrows-left-right-to-line"></i>
            <p className="driverbar_value">{distanse}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

function convertDistance(distanceMeters) {
  // Convert distance from meters to kilometers
  var distanceKm = distanceMeters / 1000;
  return distanceKm.toFixed(2) + " Km";
}

function convertDuration(durationSeconds) {
  // Convert duration from seconds to HH:MM format
  var hours = Math.floor(durationSeconds / 3600);
  var minutes = Math.floor((durationSeconds % 3600) / 60);
  return formatTime(hours) + "h" + formatTime(minutes) + "m";
}

function formatTime(time) {
  // Format time with leading zero if necessary
  return time < 10 ? "0" + time : time;
}

function getLocationRequest(journey) {
  // Get the waypoints from the response object
  let waypoints = [
    {
      location: {
        lat: null,
        lng: null,
      },
    },
  ];
  let origin = {
    location: {
      lat: null,
      lng: null,
    },
  };

  let destination = {
    location: {
      lat: null,
      lng: null,
    },
  };

 
  let orderAndStopResponseList = [];
  console.log("journey.journeyResponse.status");
  console.log(journey);
  if (journey.journeyResponse.status === "SUGGESTED") {
    orderAndStopResponseList = getSortedOrderAndStopListByGooglePredictNum(
      journey.orderAndStopResponseList
    );
  } else {
    orderAndStopResponseList = getSortedOrderAndStopListByActualNo(
      journey.orderAndStopResponseList
    );
  }
  waypoints = getWaypoints(orderAndStopResponseList);
  origin = waypoints.pop();
  destination = origin;
  return { origin, destination, waypoints ,orderAndStopResponseList};
}

function getSortedOrderAndStopListByGooglePredictNum(orderAndStopResponseList) {
  return orderAndStopResponseList.sort(
    (a, b) => a.stopResponse.googlePredictNo - b.stopResponse.googlePredictNo
  );
}
function getSortedOrderAndStopListByActualNo(orderAndStopResponseList) {
  return orderAndStopResponseList.sort(
    (a, b) => a.stopResponse.actualNo - b.stopResponse.actualNo
  );
}

function getWaypoints(shortedOrderAndStopList) {
  const waypoints = [];

  for (const item of shortedOrderAndStopList) {
    const { latitude, longitude } = item.stopResponse;
    waypoints.push({
      location: { lat: latitude, lng: longitude },
    });
  }

  return waypoints;
}

const getColorForRoute = (routeIndex) => {
  const colors = ["#801813", "#196C2D", "#194B6C", "#3C2555", "#8A1971"];
  return colors[(routeIndex - 1) % colors.length];
};

const handleSuccess = (message) => {
  toast.success(message);
  // reloadPageAfterDelay();
};

const handleError = (message) => {
  toast.error(message);
  // reloadPageAfterDelay();
};

const reloadPageAfterDelay = () => {
  setTimeout(() => {
    window.location.reload();
  }, 2000);
};

export default ViewJourney;
