import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

const ChangeOrder = (props) => {
        const [journey, setJourney] = React.useState({});
        const [updateOrderAndStopList, setUpdateOrderAndStopList] = React.useState([]);
        const handleMoveUp = (index) => {
                if (index > 0) {
                    
                        const updatedList = [...updateOrderAndStopList];
                        const temp = updatedList[index];
                        updatedList[index] = updatedList[index - 1];
                        updatedList[index - 1] = temp;
                        setUpdateOrderAndStopList(updatedList);
                }
        };

        const handleMoveDown = (index) => {
                if (index < updateOrderAndStopList.length - 1) {
                  const updatedList = [...updateOrderAndStopList];
                  const temp = updatedList[index];
                  updatedList[index] = updatedList[index + 1];
                  updatedList[index + 1] = temp;
                  setUpdateOrderAndStopList(updatedList);
                }
        };
        

        const updateOrder = async () => {   
            const stopIds = updateOrderAndStopList.map(
              (stop) => stop.stopResponse.id
            );
            console.log("stopIds");
            console.log(stopIds);
            try {
               await axios
                  .put(
                    `http://35.192.79.231:8080/api/v1/journey/updateJourneyAndStop/${journey.journeyResponse.id}`,
                    stopIds
                  )
                  .then((response) => {
                    console.log(response);
                     props.setStaus("SUCCESS");
                     props.setMessage("Order Updated successfully");
                     props.onHide();
                   
                  });
            } catch (error) {
                console.error("Error updating order:", error);
                props.setStaus("ERROR");
                props.setMessage("Error updating order");
            }
        };

        function handleChangeOrder(journey) {
            setJourney(journey);
            setUpdateOrderAndStopList(journey.orderAndStopResponseList);
        }
        
        

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Confirm or Change the Order
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>Select a Journey</p>
                {props.journeyList.map((journey, index) => (
                  <div key={journey.id} className="p-3">
                    <input
                      type="radio"
                      name="journeyOption"
                      value={journey.id}
                      onChange={() => handleChangeOrder(journey)}
                      className="px-3"
                    />
                    <label>
                      <h5 className="mx-3">
                        {journey.journeyResponse.workOrder}
                      </h5>
                    </label>
                  </div>
                ))}
              </div>
              <table>
                <tbody>
                  {journey.orderAndStopResponseList &&
                    updateOrderAndStopList.map((stop, index) => (
                      <tr key={index}>
                        {stop.orderResponse != null && (
                          <React.Fragment>
                            <td>{stop.stopResponse.stopName}</td>
                            <td>
                              <Button
                                onClick={() => handleMoveUp(index)}
                                className="m-3"
                                size="sm"
                                variant="outline-success"
                              >
                                Up
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={() => handleMoveDown(index)}
                                className="m-3"
                                size="sm"
                                variant="outline-warning"
                              >
                                Down
                              </Button>
                            </td>
                          </React.Fragment>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              {
                <Button variant="success" onClick={() => updateOrder()}>
                  Update
                </Button>
              }
              {/* {!update && <Button>Confirm</Button>} */}
              <Button onClick={props.onHide} variant="outline-primary">
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        );
};

export default ChangeOrder;
