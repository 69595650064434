import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageLayout from "./layouts/mainLayout/PageLayout";
import ViewJourney from "./pages/viewJourney";
import ViewDistributingGrp from "./pages/distributingGrp";
import CompareModel from "./pages/viewJourney/CompareModel";
 
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PageLayout />}>
          <Route index element={<ViewDistributingGrp />} />
          <Route path="viewJourney/:id" element={<ViewJourney />} />
          <Route path="compareModel/:id" element={<CompareModel />} />
        </Route>
      </Routes>
    </Router>
  );
};
 
export default App;