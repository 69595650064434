import React, { useEffect,useState } from "react";
import "../style/map.css";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function MapComponent({ routes }) {

  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    // Check if the Google Maps API is loaded
    if (window.google) {
      setMapLoaded(true);
    } else {
      // Wait for the Google Maps API to be loaded
      const checkGoogleMaps = setInterval(() => {
        if (window.google) {
          setMapLoaded(true);
          clearInterval(checkGoogleMaps);
        }
      }, 1000); // Check every second
    }
  }, []);

  useEffect(() => {
    if (mapLoaded) {
      initializeMap();
    }
  }, [mapLoaded, routes]);
  
    const initializeMap = () => {
      const directionsService = new window.google.maps.DirectionsService();
      const mapElement = document.getElementById("map");

      const map = new window.google.maps.Map(mapElement, {
        zoom: 10,
        center: { lat: 23.8103, lng: 90.4125 },
      });

      if(!map){
        return "Map is Loading...";
      }


      routes.forEach((route, index) => {
        if (route != null) {
          calculateAndDisplayRoute(directionsService, map, route, index + 1);
        } else {
          console.log("route is null");
          return;
        }
      });
    };

    const calculateAndDisplayRoute = (
      directionsService,
      map,
      route,
      routeIndex
    ) => {
      directionsService.route(
        {
          origin: route.origin,
          waypoints: route.waypoints,
          destination: route.destination,
          optimizeWaypoints: false,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK") {
            var infowindow = new window.google.maps.InfoWindow();
            const directionsRenderer =
              new window.google.maps.DirectionsRenderer({
                map: map,
                preserveViewport: true,
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: route.color.color,
                },
              });

            directionsRenderer.setDirections(response);
            showRouteDetails(response, routeIndex);
            var my_route = response.routes[0];
            
            var OriginMarker = new window.google.maps.Marker({
              position: my_route.legs[0].start_location,
              label: "" + ("W"),
              title: route.orderAndStopResponseList[route.orderAndStopResponseList.length-1].stopResponse.stopName,
              map: map,
            });


            for (var i = 1; i < my_route.legs.length; i++) {
              var marker = new window.google.maps.Marker({
                position: my_route.legs[i].start_location,
                label: "" + (i),
                title: route.orderAndStopResponseList[i-1].stopResponse.stopName,
                map: map,
              });
              window.google.maps.event.addListener(
                marker,
                "click",
                (function (marker, i) {
                  return function () {
                    // Set the content of the info window
                    infowindow.setContent(getContent(route.orderAndStopResponseList[i-1],i));

                    // Open the info window
                    infowindow.open(map, marker);
                  };
                })(marker, i)
              );
            }
            var marker = new window.google.maps.Marker({
              position: my_route.legs[0].start_location,
              label: "" + ("W"),
              title:
                route.orderAndStopResponseList[(route.orderAndStopResponseList.length)-1].stopResponse.stopName,
              map: map,
            });
          } else {
            window.alert("Directions request failed due to " + status);
          }
        }
      );
    };

    const distanceList = [];

    const showRouteDetails = (directionsResult, routeIndex) => {
      const route = directionsResult.routes[0];
      const summaryPanel = document.getElementById("directions-panel");

      summaryPanel.innerHTML += `<b>Route Segment: ${routeIndex}</b><br>`;
      route.legs.forEach((leg, index) => {
        const routeSegment = index + 1;

        summaryPanel.innerHTML += `${leg.start_address} to `;
        summaryPanel.innerHTML += `${leg.end_address}<br>`;
        summaryPanel.innerHTML += `${leg.distance.text}<br><br>`;
        distanceList.push(leg.distance.value);
      });
    };


  return (
    <div>
      <div id="map" style={{ height: "600px", width: "100%" }}></div>
      <div id="directions-panel"></div>
      
    </div>
  );
}
function getContent(stop, index) {
  var contentString = `
    <div style="font-family: Arial, sans-serif;">
      <h5>${stop.stopResponse.stopName}</h5>
      <table style="width:100%; border-collapse: collapse;">
       <tr>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;"><strong>Stop No:</strong></td>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;">${index}</td>
        </tr>
        <tr>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;"><strong>Address:</strong></td>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;">${
            stop.stopResponse.stopAddress
          }</td>
        </tr>
        <tr>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;"><strong>OrderRefId:</strong></td>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;">${
            stop.orderResponse !== null ? stop.orderResponse.orderRefId : "N/A"
          }</td>
        </tr>
        <tr>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;"><strong>Weight:</strong></td>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;">${
            stop.orderResponse !== null
              ? stop.orderResponse.weight + " Kg"
              : "N/A"
          }</td>
        </tr>
        <tr>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;"><strong>Volume:</strong></td>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;">${
            stop.orderResponse !== null
              ? stop.orderResponse.volume + "CBM"
              : "N/A"
          } </td>
        </tr>
        <tr>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;"><strong>Arrival Time:</strong></td>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;">${getReadableDateTime(
            stop.stopResponse.arrivedTime
          )}</td>
        </tr>
        <tr>
          <td style="padding: 5px;"><strong>Departure Time:</strong></td>
          <td style="padding: 5px;">${
            stop.orderResponse !== null
              ? getReadableDateTime(stop.stopResponse.departedTime)
              : "N/A"
          }</td>
        </tr>
      </table>
    </div>`;

  return contentString;
}





function getReadableDateTime(dateTimeString) {
  const date = new Date(dateTimeString);

  // Get the year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(date.getDate()).padStart(2, "0");

  // Get hours, minutes, and seconds
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Determine AM or PM
  const amOrPm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Construct the readable date-time string
  const readableDateTime = `${year}-${month}-${day} ${formattedHours}:${minutes}:${seconds} ${amOrPm}`;

  return readableDateTime;
}

export default MapComponent;
