import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import InitialMap from "../Map/initialMap";
import GoogleMap from "../Map/GoogleMap";
import ActualMap from "../Map/AutualMap";
import "../../style/compareModel.css";

const CompareModel = () => {
  const { id } = useParams();
  const [journey, setJourneyData] = useState(null);

    const [initialOrderAndStopList, setInitialOrderAndStopList] = useState([]);
    const [initialOrigin, setInitialOrigin] = useState({});
    const [initialDestination, setInitialDestination] = useState({});
    const [initialWaypoints, setInitialWaypoints] = useState([]);
    const [initalDistanseList, setinitialDistanceList] = useState([]);

    const [googleOrderAndStopList, setGoogleOrderAndStopList] = useState([]);
    const [googleOrigin, setGoogleOrigin] = useState({});
    const [googleDestination, setGoogleDestination] = useState({});
    const [googleWaypoints, setGoogleWaypoints] = useState([]);
    const [googleDistanseList, setGoogleDistanceList] = useState([]);

    const [actualOrderAndStopList, setActualOrderAndStopList] = useState([]);
    const [actualOrigin, setActualOrigin] = useState({});
    const [actualDestination, setActualDestination] = useState({});
    const [actualWaypoints, setActualWaypoints] = useState([]);
    const [actualDistanseList, setActualDistanceList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://35.192.79.231:9090/ROUTING-SERVICE/api/v1/journey/getJourneyAndRoute/${id}`
        );
        const journeyData = response.data.data;
        setJourneyData(journeyData);

        // Google suggested route
        const shortedOrderAndStopListGoogle =
          getSortedOrderAndStopListByGooglePredictNum(
            journeyData.orderAndStopResponseList
          );
        setGoogleOrderAndStopList(shortedOrderAndStopListGoogle);
        const waypointsGoogle = getWaypoints(shortedOrderAndStopListGoogle);
        const originGoogle = waypointsGoogle.pop();
        setGoogleOrigin(originGoogle);
        setGoogleDestination(originGoogle);
        setGoogleWaypoints(waypointsGoogle);

        // Actual route
        const shortedOrderAndStopListActual =
          getSortedOrderAndStopListByActualNo(
            journeyData.orderAndStopResponseList
          );
        setActualOrderAndStopList(shortedOrderAndStopListActual);
        const waypointsActual = getWaypoints(shortedOrderAndStopListActual);
        const originActual = waypointsActual.pop();
        setActualOrigin(originActual);
        setActualDestination(originActual);
        setActualWaypoints(waypointsActual);

        // Route by initiate stop number
        const shortedOrderAndStopListByInitiateStopNo =
          getSortedOrderAndStopListByInitiateStopNo(
            journeyData.orderAndStopResponseList
          );
        setInitialOrderAndStopList(shortedOrderAndStopListByInitiateStopNo);
        const waypointsByInitiateStopNo = getWaypoints(
          shortedOrderAndStopListByInitiateStopNo
        );
        const originByInitiateStopNo = waypointsByInitiateStopNo.pop();
        setInitialOrigin(originByInitiateStopNo);
        setInitialDestination(originByInitiateStopNo);
        setInitialWaypoints(waypointsByInitiateStopNo);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };


    fetchData();
  }, [id]);
  if (!journey) {
    return <div>Loading...</div>;
  } 

  return (
    <div className="p-4">
      <h1 className="text-center">Compare Model</h1>
      <div className="d-flex mb-3 p-4" style={{ width: "100%" }}>
        <div className="map_component">
          <h2>Initial Route</h2>
          <InitialMap
            origin={initialOrigin}
            destination={initialDestination}
            waypoints={initialWaypoints}
            orderAndStopList={initialOrderAndStopList}
            setinitialDistanceList={setinitialDistanceList}
          />
          <ShopTable
            orderAndStopList={initialOrderAndStopList}
            costPerKm={journey.journeyResponse.vehicleResponse.costPerKm}
            distanseList={initalDistanseList}
          />
        </div>
        <div className="map_component">
          <h2>Google sugessted route</h2>
          <GoogleMap
            origin={googleOrigin}
            destination={googleDestination}
            waypoints={googleWaypoints}
            orderAndStopList={googleOrderAndStopList}
            setGoogleDistanceList={setGoogleDistanceList}
          />
          <ShopTable
            orderAndStopList={googleOrderAndStopList}
            costPerKm={journey.journeyResponse.vehicleResponse.costPerKm}
            distanseList={googleDistanseList}
          />
        </div>
        <div className="map_component">
          <h2>Actual Route</h2>
          <ActualMap
            origin={actualOrigin}
            destination={actualDestination}
            waypoints={actualWaypoints}
            orderAndStopList={actualOrderAndStopList}
            setActualDistanceList={setActualDistanceList}
          />
          <ShopTable
            orderAndStopList={actualOrderAndStopList}
            costPerKm={journey.journeyResponse.vehicleResponse.costPerKm}
            distanseList={actualDistanseList}
          />
        </div>
      </div>
    </div>
  );
};

const ShopTable = (props) => {
  console.log(props.orderAndStopList);
  var totalCost = 0;
  props.distanseList.forEach((distance) => {
    totalCost += parseFloat(distance) * props.costPerKm;
  });
    return (
      <table className="shop-table p-3 table table-bordered table-striped">
        <tbody>
          <tr  className="table-dark">
            <th  className="p-3">
              Stop No
            </th>
            <th  className="p-3">
              Stop Name
            </th>
            <th  className="p-3">
              Distance
            </th>
            <th  className="p-3">
              Cost X {props.costPerKm}
            </th>
          </tr>
          {props.orderAndStopList.map((stop, index) => (
            <tr key={index} >
              {stop.stopResponse != null && (
                <React.Fragment>
                  <td  className="p-3">
                    {index + 1}
                  </td>
                  <td  className="p-3">
                    {stop.stopResponse.stopName}
                  </td>
                  <td  className="p-3">
                    {props.distanseList[index] != null &&
                      props.distanseList[index]}
                  </td>
                  <td  className="p-3">
                    {props.distanseList[index] != null &&
                      (
                        parseFloat(props.distanseList[index]) * props.costPerKm
                      ).toFixed(2)}
                  </td>
                </React.Fragment>
              )}
            </tr>
          ))}
          <tr >
            <th></th>
            <th></th>
            <th  className="p-3">
              Total Cost
            </th>
            <th  className="p-3">
              {totalCost.toFixed(2)} BDT
            </th>
          </tr>
        </tbody>
      </table>
    );
}


function getSortedOrderAndStopListByGooglePredictNum(orderAndStopResponseList) {
  return orderAndStopResponseList
    .slice()
    .sort(
      (a, b) => a.stopResponse.googlePredictNo - b.stopResponse.googlePredictNo
    );
}

function getSortedOrderAndStopListByActualNo(orderAndStopResponseList) {
  return orderAndStopResponseList
    .slice()
    .sort((a, b) => a.stopResponse.actualNo - b.stopResponse.actualNo);
}

function getSortedOrderAndStopListByInitiateStopNo(orderAndStopResponseList) {
  return orderAndStopResponseList
    .slice()
    .sort(
      (a, b) => a.stopResponse.initiateStopNo - b.stopResponse.initiateStopNo
    );
}

function getWaypoints(shortedOrderAndStopList) {
  return shortedOrderAndStopList.map((item) => ({
    location: {
      lat: item.stopResponse.latitude,
      lng: item.stopResponse.longitude,
    },
  }));
}

export default CompareModel;
