import React, {useEffect, useState} from 'react';
import axios from 'axios';
import "../../style/distributing.css";
import { Link } from 'react-router-dom';


const ViewDistributingGrp = () => {
    const[distributingGrp, setDistributingGrp] = useState([]);
     useEffect(() => {
       axios
         .get(
           "http://35.192.79.231:9090/ROUTING-SERVICE/api/v1/distributorGroup/getAllDistributorGroups"
         )
         .then((response) => {
           setDistributingGrp(response.data.data);
           console.log(response.data.data);
         })
         .catch((error) => {
           console.error(error);
         });
     }, []);

    return (
      <div class="container">
        <h1 class="mt-5 mb-4">View Distributing Group</h1>
        <div class="row">
          {distributingGrp.map((distributing, index) => (
            <div class="col-lg-4 col-md-6 col-sm-12 mb-4 ">
              <Link to={`/viewJourney/${distributing.id}`} style={{ textDecoration: 'none' }}>
                <div class="card h-100 disGrps">
                  <div class="card-body">
                    <h5 class="card-title">{distributing.name}</h5>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
};

export default ViewDistributingGrp;