import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

const ReverseRoute = (props) => {
  const [journey, setJourney] = React.useState({});

  const updateOrder = async () => {
    const stopIds = journey.orderAndStopResponseList.map(
      (stop) => stop.stopResponse.id
    );
    // reverse the order of the stopIds
    stopIds.reverse();
    try {
      await axios
        .put(
          `http://35.192.79.231:8080/api/v1/journey/updateJourneyAndStop/${journey.journeyResponse.id}`,
          stopIds
        )
        .then((response) => {
          console.log(response);
          props.setStaus("SUCCESS");
          props.setMessage("Order Reversed successfully");
          props.onHide();
        });
    } catch (error) {
      console.error("Error Reversed order:", error);
      props.setStaus("ERROR");
      props.setMessage("Error Reversed order");
    }
  };

  function handleChangeOrder(journey) {
    setJourney(journey);
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Reverse Route
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>Select a journey to reverse the route</p>
          {props.journeyList.map((journey, index) => (
            <div key={journey.id} className="p-3">
              <input
                type="radio"
                name="journeyOption"
                value={journey.id}
                onChange={() => handleChangeOrder(journey)}
                className="px-3"
              />
              <label>
                <h5 className="mx-3">{journey.journeyResponse.workOrder}</h5>
              </label>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {journey.journeyResponse && (
          <Button variant="success" onClick={() => updateOrder()}>
            Reverse
          </Button>
        )}
        {/* {!update && <Button>Confirm</Button>} */}
        <Button onClick={props.onHide} variant="outline-primary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReverseRoute;
