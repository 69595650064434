import React from "react";
import { Outlet } from "react-router-dom";
// import Sidebar from "../sidebar/sidebar";
// import Header from "../headers/sampleHeader";
import SampleHeader from "..//headers/sampleHeader";
import 'bootstrap/dist/css/bootstrap.min.css';



const PageLayout = () => {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        {/* <SampleHeader /> */}
        <div style={{ display: "flex", flex: 1 }}>
          {/* <Sidebar /> */}
          <div className="classoutlet" style={{ flex: 1, overflow: "auto" }}>
            <Outlet />
          </div>
        </div>
      </div>
    );
  }   

export default PageLayout;