import React from "react";
import { Button } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
 
 
const JourneyDetails = ({ journeyList }) => {
  function handleButtonClick(row) {
    if (row.status === "SUGGESTED") {
     const statusData = "PENDING";
 
      axios
        .put(
          "http://35.192.79.231:8080/api/v1/journey/updateJourneyStatus/" + row.id,{
            statusData
          }
        )
        .then((response) => {
          console.log(response.data.data);
          toast.success(response.data.msg);
          // wait 2 seconds before refreshing the page
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
        })
        .catch((error) => {
          toast.error("Error updating status");
          console.error(error);
        });
      
      
    
    }
    
  }
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "workOrder", headerName: "Work Order", width: 130 },
    { field: "status", headerName: "Status", width: 130 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const { row } = params;
        const buttonText = row.status === "SUGGESTED" ? "Confirm" : "Compare";
        const buttonColor = row.status === "SUGGESTED" ? "green" : "#235CEB";
 
        if (row.status === "SUGGESTED") {
           return(
            <Button
             className="sm"
             onClick={() => handleButtonClick(row)}
             style={{ backgroundColor: buttonColor }}
           >
             {buttonText}
           </Button>
           );
        } else {
          return (
            <Link
              to={`/compareModel/${row.id}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                className="sm"
                style={{ backgroundColor: buttonColor }}
              >
                {buttonText}
              </Button>
            </Link>
          );
        }
      },
    },
    {
      field: "journeyCreatedAt",
      headerName: "Journey Created At",
      width: 150,
    },
    {
      field: "journeyStartedAt",
      headerName: "Journey Started At",
      width: 150,
    },
    {
      field: "journeyCompletedAt",
      headerName: "Journey Completed At",
      width: 130,
    },
    { field: "totalDistance", headerName: "Total Distance", width: 130 },
    { field: "totalDuration", headerName: "Total Duration", width: 130 },
    { field: "totalWeight", headerName: "Total Weight", width: 130 },
    { field: "totalVolume", headerName: "Total Volume", width: 130 },
    { field: "totalCost", headerName: "Total Cost", width: 130 },
    { field: "numOfStops", headerName: "Number Of Stops", width: 130 },
    { field: "fixedRouteId", headerName: "Fixed Route Id", width: 130 },
    { field: "vehicle", headerName: "Vehicle", width: 130 },
    { field: "driver", headerName: "Driver", width: 130 },
  ];
 
  const rows = journeyList.map((journey) => ({
    id: journey.journeyResponse.id,
    workOrder: journey.journeyResponse.workOrder,
    status: journey.journeyResponse.status,
    // here doing some logic to is staus is Sugessted then button name and action will Confirm and is status is Confirmed then button name and action will be Start
    journeyCreatedAt: journey.journeyResponse.journeyCreatedAt,
    journeyStartedAt: journey.journeyResponse.journeyStartedAt,
    journeyCompletedAt: journey.journeyResponse.journeyCompletedAt,
    totalDistance: convertDistance(journey.journeyResponse.totalDistance),
    totalDuration: convertDuration(journey.journeyResponse.totalDuration),
    totalWeight: journey.journeyResponse.totalWeight,
    totalVolume: journey.journeyResponse.totalVolume,
    totalCost: journey.journeyResponse.totalCost,
    numOfStops: journey.journeyResponse.numOfStops,
    fixedRouteId: journey.journeyResponse.fixedRouteId,
    vehicle: journey.journeyResponse.vehicleResponse.licensePlateID,
    driver: journey.journeyResponse.driverResponse.name,
  }));
 
  return (
    <div className="p-3">
      <h1>Journey Details</h1>
      <Table columns={columns} rows={rows} />
      {/* <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" fill>
          <Tab eventKey="home" title="Journey">
            <Table columns={columns} rows={rows} />
          </Tab>
          <Tab eventKey="profile" title="Routes">
            <p>No Data</p>
          </Tab>
        </Tabs> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="Bounce"
      />
    </div>
  );
};
 
const Table = ({ columns, rows }) => {
  return (
    <div style={{ height: "fit-content", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
      />
    </div>
  );
};
 
function convertDistance(distanceMeters) {
  // Convert distance from meters to kilometers
  var distanceKm = distanceMeters / 1000;
  return distanceKm.toFixed(2) + " Km";
}
 
function convertDuration(durationSeconds) {
  // Convert duration from seconds to HH:MM format
  var hours = Math.floor(durationSeconds / 3600);
  var minutes = Math.floor((durationSeconds % 3600) / 60);
  return formatTime(hours) + "h" + formatTime(minutes) + "m";
}
 
function formatTime(time) {
  // Format time with leading zero if necessary
  return time < 10 ? "0" + time : time;
}
 
export default JourneyDetails;