import React, { useEffect } from "react";
import "../../style/map.css";

function GoogleMap({ origin, destination, waypoints, orderAndStopList, setGoogleDistanceList }) {
 
  useEffect(() => {
    const initializeGoogleMap = () => {
      const googleDirectionsService =
        new window.google.maps.DirectionsService();
      const googleDirectionsRenderer =
        new window.google.maps.DirectionsRenderer();
      const googleMapElement = document.getElementById("google-map");

      const googleMap = new window.google.maps.Map(googleMapElement, {
        zoom: 12,
        center: { lat: 6.9271, lng: 79.8612 }, // Set the center to Colombo coordinates
      });
      if (!googleMap) {
        return "Map is Loading...";
      }

      googleDirectionsRenderer.setMap(googleMap);
      googleDirectionsRenderer.setOptions({ suppressMarkers: true });
      calculateAndDisplayRoute(
        googleDirectionsService,
        googleDirectionsRenderer,
        googleMap,
        orderAndStopList
      );
    };

    const calculateAndDisplayRoute = (
      googleDirectionsService,
      googleDirectionsRenderer,
      map,
      orderAndStopList
    ) => {
      googleDirectionsService
        .route({
          origin: origin,
          waypoints: waypoints,
          destination: destination,
          optimizeWaypoints: false,
          travelMode: window.google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          handleRouteResponse(
            response,
            googleDirectionsRenderer,
            map,
            orderAndStopList
          );
        })
        .catch((error) => {
          handleRouteError(error);
        });
    };

    const handleRouteResponse = (
      response,
      directionsRenderer,
      map,
      orderAndStopList
    ) => {
      if (response && response.status === "OK") {
        const route = response.routes[0];
        const summaryPanel = document.getElementById("directions-panel");
        const googleDistanceAndTotalTime = document.getElementById(
          "google-distanceAndTotalTime"
        );

        var infowindow = new window.google.maps.InfoWindow();

        var my_route = response.routes[0];
        var marker = new window.google.maps.Marker({
          position: my_route.legs[0].start_location,
          label: "W",
          title:
            orderAndStopList[orderAndStopList.length - 1].stopResponse.stopName,
          map: map,
        });
        window.google.maps.event.addListener(
          marker,
          "click",
          (function (marker) {
            return function () {
              // Set the content of the info window
              infowindow.setContent(
                getContent(
                  orderAndStopList[orderAndStopList.length - 1],
                  orderAndStopList.length
                )
              );
              infowindow.open(map, marker);
            };
          })(marker)
        );
        for (var i = 1; i < my_route.legs.length; i++) {
          var marker = new window.google.maps.Marker({
            position: my_route.legs[i].start_location,
            label: "" + i,
            title: orderAndStopList[i - 1].stopResponse.stopName,
            map: map,
          });
          window.google.maps.event.addListener(
            marker,
            "click",
            (function (marker, i) {
              return function () {
                // Set the content of the info window
                infowindow.setContent(getContent(orderAndStopList[i - 1], i));

                // Open the info window
                infowindow.open(map, marker);
              };
            })(marker, i)
          );
        }

        googleDistanceAndTotalTime.innerHTML = "";

        summaryPanel.innerHTML = "";

        let totalDistance = 0;
        let totalTime = 0;

        var distanceList = [];

        route.legs.forEach((leg, index) => {
          const routeSegment = index + 1;

          summaryPanel.innerHTML += `<b>Route Segment: ${routeSegment}</b><br>`;
          summaryPanel.innerHTML += `${leg.start_address} to `;
          summaryPanel.innerHTML += `${leg.end_address}<br>`;
          summaryPanel.innerHTML += `${leg.distance.text}<br><br>`;
          distanceList.push(leg.distance.text);
          totalDistance += leg.distance.value;
          totalTime += leg.duration.value;
        });
        setGoogleDistanceList(distanceList);
        const totalGoogleDistanceText =
          (totalDistance / 1000).toFixed(2) + " km";
        const totalGoogleTimeText = formatTime(totalTime);

        googleDistanceAndTotalTime.innerHTML = `<b>Total Distance:</b> ${totalGoogleDistanceText}<br>`;
        googleDistanceAndTotalTime.innerHTML += `<b>Total Time:</b> ${totalGoogleTimeText}<br>`;

        directionsRenderer.setDirections(response);
      } else {
        throw new Error(
          "No route could be found between the origin and destination."
        );
      }
    };

    const handleRouteError = (error) => {
      window.alert("Directions request failed due to " + error.message);
    };
    const formatTime = (timeInSeconds) => {
      const hours = Math.floor(timeInSeconds / 3600);
      const minutes = Math.floor((timeInSeconds % 3600) / 60);
      const seconds = timeInSeconds % 60;

      return `${hours}h ${minutes}m ${seconds}s`;
    };

    initializeGoogleMap();
  }, [origin, destination, waypoints]);

  return (
    <div>
      <div id="google-map" style={{ height: "600px", width: "100%" }}></div>
      <div id="directions-panel"></div>
      <div id="google-distanceAndTotalTime"></div>
    </div>
  );
}

function getContent(stop, index) {
  var contentString = `
    <div style="font-family: Arial, sans-serif;">
      <h5>${stop.stopResponse.stopName}</h5>
      <table style="width:100%; border-collapse: collapse;">
       <tr>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;"><strong>Stop No:</strong></td>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;">${index}</td>
        </tr>
        <tr>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;"><strong>Address:</strong></td>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;">${
            stop.stopResponse.stopAddress
          }</td>
        </tr>
        <tr>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;"><strong>OrderRefId:</strong></td>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;">${
            stop.orderResponse !== null ? stop.orderResponse.orderRefId : "N/A"
          }</td>
        </tr>
        <tr>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;"><strong>Weight:</strong></td>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;">${
            stop.orderResponse !== null
              ? stop.orderResponse.weight + " Kg"
              : "N/A"
          }</td>
        </tr>
        <tr>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;"><strong>Volume:</strong></td>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;">${
            stop.orderResponse !== null
              ? stop.orderResponse.volume + "CBM"
              : "N/A"
          } </td>
        </tr>
        <tr>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;"><strong>Arrival Time:</strong></td>
          <td style="padding: 5px; border-bottom: 1px solid #ddd;">${getReadableDateTime(
            stop.stopResponse.arrivedTime
          )}</td>
        <tr>
          <td style="padding: 5px;"><strong>Departure Time:</strong></td>
          <td style="padding: 5px;">${
            stop.orderResponse !== null
              ? getReadableDateTime(stop.stopResponse.departedTime)
              : "N/A"
          }</td>
        </tr>
      </table>
    </div>`;

  return contentString;
}

function getReadableDateTime(dateTimeString) {
  const date = new Date(dateTimeString);

  // Get the year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(date.getDate()).padStart(2, "0");

  // Get hours, minutes, and seconds
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Determine AM or PM
  const amOrPm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Construct the readable date-time string
  const readableDateTime = `${year}-${month}-${day} ${formattedHours}:${minutes}:${seconds} ${amOrPm}`;

  return readableDateTime;
}


export default GoogleMap;
